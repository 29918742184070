import React, {useState} from "react";
import Popup from "./Popup"; // https://github.com/cluemediator/react-popup
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"; // https://www.npmjs.com/package/react-pdf
import rf_preview_pdf from "../res/Rocky_Frontier_Preview.pdf";
import deadlock_preview_pdf from "../res/Deadlock_Preview.pdf";
import rocky_frontier_cover from "../res/rocky_frontier_cover.jpg";
import deadlock_cover from "../res/deadlock_cover.png";

const rf_buy_link ="https://www.amazon.com/Rocky-Frontier-ESSO-Book-1-ebook/dp/B09TCZF8FG/ref=sr_1_1"
const deadlock_buy_link="https://www.amazon.com/dp/B0DGXSGQJX"
//import amazon_link from "../res/amazon-link.png";

function Book(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function togglePopup() {
        setIsOpen(!isOpen);
    }

   function handleClick () {
      console.log('Button clicked!');
      };

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    function incrPageNum() {
        if (pageNumber + 1 <= numPages) { setPageNumber(pageNumber + 1); }
    }

    function decrPageNum() {
        if (pageNumber - 1 > 0) { setPageNumber(pageNumber - 1); }
    }

    function firstPageNum() {
        setPageNumber(1);
    }

    function lastPageNum() {
        setPageNumber(numPages);
    }

    function getImg(name) {
        if (name === "rocky_frontier_cover") { return rocky_frontier_cover; }
        if (name === "deadlock_cover") { return deadlock_cover; }
    }

    function getPreview(name) {
        if (name === "rocky_frontier_cover") { return rf_preview_pdf; }
        if (name === "deadlock_cover") { return deadlock_preview_pdf; }
    }

    function getBuyLink(name){
        if (name === "Rocky Frontier") { return rf_buy_link; }
        if (name === "Deadlock") { return deadlock_buy_link; }
    }

    return (
        <div key={props.id} className="Book">
            <div className="book-grid-container">
                <div className="book-grid-item book-item1">
                    <button className="book-popup-button" onClick={togglePopup}>
                        <img className="book-item1-img" src={getImg(props.image)} alt={props.name} />
                    </button>
                </div>
                <div className="book-grid-item book-item2">
                    <h2 className="book-title">{props.series}: {props.name}</h2>
                    <p className="book-author">By {props.author}</p>
                    <p className="book-description">{props.description}</p>
                    <div className="incentives" >
                    
                        <button onClick={togglePopup} className="preview-btn">Read Preview</button>
                        
          
                        </div> 
                        <div className="buyButton">
                        <a className="amazon-link" href= {getBuyLink(props.name)} target="_blank" rel="noopener noreferrer">
                
                <button style={{ height:60 , width: 240, fontSize: 30, margin: 0}} onClick={handleClick}>Buy on Amazon</button></a>
                        </div>
                    
                    
                </div>
            </div>
            {isOpen && <Popup
                content={<>
                    <Document file={getPreview(props.image)} onLoadSuccess={onDocumentLoadSuccess} scale={20} renderMode="canvas" renderTextLayer={false}>
                        <Page pageNumber={pageNumber} />
                    </Document>
                    <div className="pdf-controls">
                        <button onClick={firstPageNum}>First</button>
                        <button onClick={decrPageNum}>Prev</button>
                        <p>Page {pageNumber} of {numPages}</p>
                        <button onClick={incrPageNum}>Next</button>
                        <button onClick={lastPageNum}>Last</button>
                    </div>
                </>}
                handleClose={togglePopup}
            />}
        </div>
    );
}

export default Book;