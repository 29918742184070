import React from "react";
import vonique from "./Schematics/vonique-64/Vonique 64 Bold.ttf"

var marginAmount = '8%';

function Documents(){
    return(
    <div ClassName = "Documents" style = {{display: "flex", flexDirection: "column", justifyContent: "center", 
    alignItems:"center", }} >
       <div style = {{flex:1, marginLeft: marginAmount, marginRight: marginAmount}}>
        <h1 style = {styles.heading}> Documents </h1>
        </div>

        
        

        <div style = {{flex:1, marginLeft: marginAmount, marginRight: marginAmount}}>
        
        <a style = {styles.entry} href = "./ESSONewsArticle1"> Article: Outrage at Robotic Security</a>
        
        </div>


        <br></br>
        


        <div style = {{flex:1, marginLeft: marginAmount, marginRight: marginAmount}}>
        
        <a style = {styles.entry} href = "./ESSONewsArticle2"> Article: SOMTA Strikes the Deal: Next Step for ESSO</a>
        
        </div>



    </div> 
    )

}

const styles = ({
    heading: { color: "red", 
    fontSize: 60, 
    fontFamily: vonique

    }, 
    entry: {
        color: "red",
         fontSize: 25, 
         fontFamily: vonique


    }
}
    )

    

export default Documents;