import React from "react";

import vonique from "../Schematics/vonique-64/Vonique 64 Bold.ttf";
var pmarginAmount = '5%';



function  ESSOProgramEntry(){
    return(
        <div ClassName = "ESSOProgramEntry" style = {{backgroundColor: "black"}} >
            <div style = {styles.titleContainer}>
            <div style = {styles.title}>
                <h1>ESSO Program</h1>

            </div>
            </div>

            <div style = {styles.paragraphContainer}>
                <p style = {styles.paragraph}>
                The ESSO[Eh-So] Program is the primary and sole interstellar space colonization program headed by the SOMTA Corporation and sponsored by the Global Government. 
                At the time of Rocky Frontier, it was founded decades ago and is already well-established. ESSO is an acronym for Extra-Solar Settlement Operation. 
                The ESSO Program is headed by the ESSO Company, run by its own Board of Executives. 
                SOMTA deals in other sectors such as intra-solar space travel contracts, and has its origins in Artificial Intelligence and Technology Development, but ESSO has grown to become its most prominent and influential branch. 
                </p>
            </div>

           <br></br>
           <br></br>

            <div style = {styles.paragraphContainer}>
                <p style = {styles.paragraph}>There are three ESSO Colonies currently in operation at the time of Rocky Frontier, ESSO-1, ESSO-2, and ESSO-3. 
                Each Colony has its own unique environment, but they all fit a few essential criteria, such as hosting fresh water, and the ability to support or evidence of an already existing native population of microbial life. 
                </p>

            </div>

            <br></br>
            <br></br>

            <div style = {styles.paragraphContainer}>
                <p style = {styles.paragraph}>Transportation to an ESSO Colony is an extremely demanding feat, due to the vast interstellar distances from Earth to even the closest star systems. 
                SOMTA’s prized Transport Vessels, with nuclear fusion engines that can perform near-light travel accelerating to up to 75% the speed of light, are utilized for interstellar journeys. 
                SOMTA commands a fleet of around thirty of these vessels in regular transit between the three colonies. The ESSO-1 and ESSO-2 colonies are in neighboring systems and thus utilize a combined route; ESSO-3 is in its own remote sector. 
                Even with their impressive speeds, the Transport Vessels take several years each way per trip to an ESSO Colony, which range from 5 to 7 light-years from Earth. 
                Transport Vessels utilize cryogenic stasis, Artificial Gravity generated via the center-seeking force within a constantly rotating ring section of the vessel, and other modern technologies that make interstellar space flight not only safe for humans, but also virtually instantaneous for the passenger with only negligible aging occurring. 
                </p>
            </div>

            <br></br>
            <br></br>

            <div style = {styles.paragraphContainer}>
                <p style = {styles.paragraph}>ESSO Colonists are selected with great scrutiny, with SOMTA undertaking massive recruitment programs on their campuses across Earth and the Solar System to gather the most promising talent. 
                ESSO recruitment emphasizes specialization in regards to occupation. Key sectors include Security, Science, Engineering, and Medicine. The ESSO Program is also known to recruit colonists outside of these four specializations, if special talent is shown in specific fields, such as aerial piloting for instance. 
                Due to the time spans of the vast interstellar journey to and from an ESSO Colony, in addition to a typical 27-month service time, ESSO service is a monumental and once-in-a-lifetime commitment for the individual. Many still join ESSO despite these costs for a variety of reasons.
                 </p>
            </div>

            <br></br>
            <br></br>

            <div style = {styles.paragraphContainer}>
                <p style = {styles.paragraph}>Chief Executives of ESSO colonies are the one notable exception to the general 27-month service time, they often oversee their ESSO Colony for years, even up to a decade. 
                Chief Executives are instated in various ways, with great care being put into their selection; often they are personnel who were chosen to stay after completing an ordinary ESSO service. More recently ESSO has run command programs during recruitment to cultivate potential talent for this role.
                 </p>
            </div>

            <br></br>
            <br></br>

            <div style = {styles.paragraphContainer}>
                <p style = {styles.paragraph}>With the massive continuing cost and challenges of sending humans interstellar, this calls into question why the ESSO Program was initialized the way it was; it was in fact not the first program of its kind. 
                Earlier in the 22nd Century Humanity first attempted interstellar travel by sending artificially intelligent adaptive robots, designed with complex algorithms to learn and rebuild themselves to adapt to their environment, and sent to lay the seeds for eventual interstellar colonization. 
                But the ensuing loss of contact and perceived disaster outcome of this initiative created a backlash that killed interest in attempting interstellar space exploration for decades to come. To this day, the fallout of the robots sent to the ESSO Colonies and the extent of their continued operation is unknown.  
                </p>
            </div>

            <br></br>
            <br></br>

            <div style = {styles.paragraphContainer}>
                <p style = {styles.paragraph}> Given the massive undertakings required for the ESSO Program to continue operation, and the fact that decades after inception the program still relies primarily on Government sponsorship, public opinion has varied wildly; it is a source of continuing controversy and political contention, among various groups and factions.
                 The ultimate outset goal is for the ESSO Program to become self-sustaining; the program currently makes the majority of its revenue not from resource exports, but rather the significant amounts of intellectual property generated from the concentration of talent in demanding and alien environments.
                  A clause of the ESSO Contract ties this intellectual property to the ESSO company exclusively. 

                </p>
            </div>

            <br></br>
            <br></br>

            <div style = {styles.paragraphContainer}>
                <p style = {styles.paragraph}> The ongoing ESSO Program represents a remarkable feat of human ingenuity and cooperation, in the unprecedented exploration of planets outside our Home System. 
                To learn more about the ESSO Program, its history, and other SOMTA operations, explore the now almost fully publicly available records of SOMTA’s archive.
                </p>
            </div>

            <br></br>
            <br></br>



        </div>

    )


}

//should put this in a separate css file so I can import it. 
//It's also used in Schematics.js
const styles = ({
    title: {
        fontSize: Math.round(window.innerWidth * 0.0351) /*(~which is about 50 for my computer screen)*/, 
        fontFamily: vonique, 
        fontWeight: "bold", 
        color : "silver"
    
        },
    titleContainer: {
        display: "flex", 
        flex: 1,
        justifyContent: "center", 
        alignItems:"center"
        
    },
    paragraphContainer:{
        display: "flex", 
        justifyContent: "center", 
        alignItems:"center", 
        marginLeft: pmarginAmount,
        marginRight: pmarginAmount
        },
    paragraph:{
        fontSize: Math.round(window.innerWidth * 0.0211) /*(~which is about 30 for my computer screen)*/, 
        fontFamily: vonique, 
        fontWeight: "bold", 
        color : "silver"
    
        },

})
export default ESSOProgramEntry;