import React from "react";

function Footer() {
    return (
        <div className="Footer">
            <p>Site by <a href="https://www.maxkuechen.com/">Maximilian Kuechen</a></p>
            <p>Copyright © 2022 Jaden Farquhar</p>
            <a href="mailto:me@maxkuechen.com">Report an Issue</a>
        </div>
    );
}

export default Footer;