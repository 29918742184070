import React, {useState} from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"; // https://www.npmjs.com/package/react-pdf
//import {Dimensions} from "react-dimensions";
import ESSONewsArticle from "./ESSO_News_Article2_(Lore).pdf"

window.pageScale = 0.001*window.innerHeight;
window.pageHeight = window.innerHeight;

function ESSONewsArticle2(){

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    function incrPageNum() {
        if (pageNumber + 1 <= numPages) { setPageNumber(pageNumber + 1); }
    }

    function decrPageNum() {
        if (pageNumber - 1 > 0) { setPageNumber(pageNumber - 1); }
    }

    function firstPageNum() {
        setPageNumber(1);
    }

    function lastPageNum() {
        setPageNumber(numPages);
    }

    return (
        <div className="stories">
            <Document file={ESSONewsArticle} onLoadSuccess={onDocumentLoadSuccess}  renderMode="canvas" renderTextLayer={false} >
                <Page scale = {window.pageScale} height = {window.pageHeight} pageNumber={pageNumber} />
            </Document>
            <div className="pdf-controls">
                <button onClick={firstPageNum}>First</button>
                <button onClick={decrPageNum}>Prev</button>
                <p>Page {pageNumber} of {numPages}</p>
                <button onClick={incrPageNum}>Next</button>
                <button onClick={lastPageNum}>Last</button>
            </div>
        </div>
    );
}



export default ESSONewsArticle2;