//Still the issue can't load theAssasination.js This is the temporary page Stories.js, in the future it'll be a table 
//of contents like Documents.js and Glossary.js

import React, {useState} from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"; // https://www.npmjs.com/package/react-pdf
//import {Dimensions} from "react-dimensions";
import theAssassinationStory from "./Stories/the_assassination.pdf"

window.pageScale = 0.001*window.innerHeight;
window.pageHeight = window.innerHeight;

function Stories(){

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    function incrPageNum() {
        if (pageNumber + 1 <= numPages) { setPageNumber(pageNumber + 1); }
    }

    function decrPageNum() {
        if (pageNumber - 1 > 0) { setPageNumber(pageNumber - 1); }
    }

    function firstPageNum() {
        setPageNumber(1);
    }

    function lastPageNum() {
        setPageNumber(numPages);
    }

    return (
        <div className="stories">
            <Document file={theAssassinationStory} onLoadSuccess={onDocumentLoadSuccess}  renderMode="canvas" renderTextLayer={false} >
                <Page scale = {window.pageScale} height = {window.pageHeight} pageNumber={pageNumber} />
            </Document>
            <div className="pdf-controls">
                <button onClick={firstPageNum}>First</button>
                <button onClick={decrPageNum}>Prev</button>
                <p>Page {pageNumber} of {numPages}</p>
                <button onClick={incrPageNum}>Next</button>
                <button onClick={lastPageNum}>Last</button>
            </div>
        </div>
    );
}



export default Stories;

/* Code for the actual Stories page, which looks just like Documents or Glossary. For some reason when I load the Assassination.js, 
it just doesn't load the pdf document. It's a weird issue

import React from "react";
import vonique from "./Schematics/vonique-64/Vonique 64 Bold.ttf"

var marginAmount = '8%';

function Stories(){
    return(
    <div ClassName = "Documents" style = {{display: "flex", flexDirection: "column", justifyContent: "center", 
    alignItems:"center", }} >
       <div style = {{flex:1, marginLeft: marginAmount, marginRight: marginAmount}}>
        <h1 style = {styles.heading}> Stories </h1>
        </div>

        
        

        <div style = {{flex:1, marginLeft: marginAmount, marginRight: marginAmount}}>
        
        <a style = {styles.entry} href = "./theAssassination"> The Assassination</a>
        
        </div>


        <br></br>
        



    </div> 
    )

}

const styles = ({
    heading: { color: "red", 
    fontSize: 60, 
    fontFamily: vonique

    }, 
    entry: {
        color: "red",
         fontSize: 25, 
         fontFamily: vonique


    }
}
    )

    

export default Stories;*/