import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import './res/App.css';
import Home from './pages/Home';
import About from './pages/About';
import Books from './pages/Books';
import Lore from './pages/Lore';
import Nav from './components/Nav';
import Footer from './components/Footer';
//Lore categories
import Documents from "./pages/Lore Pages/Documents";
import Schematics from './pages/Lore Pages/Schematics';
import Glossary from "./pages/Lore Pages/Glossary";
import Stories from "./pages/Lore Pages/Stories";

//Documents
import ESSONewsArticle1 from "./pages/Lore Pages/Documents/ESSONewsArticle1";
import ESSONewsArticle2 from "./pages/Lore Pages/Documents/ESSONewsArticle2";

//Glossary Entries
import ESSOProgramEntry from './pages/Lore Pages/Glossary/ESSOProgramEntry';
import GlobalGovernmentEntry from './pages/Lore Pages/Glossary/GlobalGovernmentEntry';

//Stories
//import theAssassinationStory from './pages/Lore Pages/Stories/theAssassination';

function App() {
  return (
    <Router>
      <div className="App">
        <Nav/>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About/>}/>
          <Route path="/books" element={<Books/>}/>
          <Route path="/lore" element={<Lore/>} />
          <Route path="/Schematics" element={<Schematics/>} />

          <Route path="/Stories" element ={<Stories/>}/>
          <Route path="/theAssassination" element = {<theAssassinationStory/>}/>

          <Route path="/Glossary" element = {<Glossary/>}/>
          <Route path="/ESSOProgramEntry" element = {<ESSOProgramEntry/>} />
          <Route path="/GlobalGovernmentEntry" element = {<GlobalGovernmentEntry/>} />

          <Route path="/Documents" element = {<Documents/>}/>
          <Route path="/ESSONewsArticle1" element = {<ESSONewsArticle1/>}/>
          <Route path="/ESSONewsArticle2" element = {<ESSONewsArticle2/>}/>
          
          <Route path="/theAssassination" element = {<theAssassination/>}/>
        </Routes>
      </div>
      <Footer/>
    </Router>
  );
}

export default App;
