import React from "react";
// import Schematics from './Lore Pages/Schematics.js';

import SOMTALogo from "../res/SOMTALogoPublicArchive.png";

function Lore() {
    return (
        <div className="Lore">
        <div className="grid-container">
            <div className="Documents" style = {styles.VerticalContainer}>
              <div style = {{flex:2}}></div>
              <p style={styles.linkText}>
                <a href="./Documents" >Documents</a>
              </p>
              <div style = {{flex:2}}></div>
                
                 
            </div>
    

        
            <div className="Stories/Schematics">
                   
                
            
            <div style={styles.horizontalContainer}>
              <p style = {styles.linkText}>
              <a  href = './Stories' >Stories</a>
              </p>
             
              <img src = {SOMTALogo} style={styles.LogoImage} alt = "SOMTALogoPublicArchive"/>
              
              <p style = {styles.linkText}>
              <a href = './Schematics' >Schematics</a>
              </p>
            </div>
           
           
                 
             </div>
            <br></br>
            <div className="Glossary" style = {styles.VerticalContainer}>
                   
                
            
              <p style={styles.glossaryLinkText}>
              <a href="./Glossary">Glossary</a>
           
              </p>
                  
                  
                        
            </div>
          

         
         
           

        </div>
    </div>
    );
}

const styles = ({
    VerticalContainer: {
      display: "flex", 
      flex:1
    },
    horizontalContainer: {
      display: "flex", 
      flex:1, 
      alignItems:"center"
    },
    linkText: {
      display:"flex", 
      flex:1, 
      fontSize: Math.round(window.innerWidth * 0.0351)/* about 50 font size on my macbook*/ 

    },
    glossaryLinkText: {
      display: "flex", 
      flex: 1, 
      fontSize: Math.round(window.innerWidth * 0.0351)/* about 50 font size on my macbook*/,   
      justifyContent: "center", 
      alignItems: "center"
    },
    LogoImage: {
      flex:2, 
      width: "40%"

    },
})

export default Lore;