import React from "react";
import MailingFrom from "../components/MailingForm";
import JadenFace from "../res/jaden.jpg"

function About() {

    return (
        <div className="About">
            <div className="grid-container">
                <div className="item1">
                    <div className="about-content">
                        <h1>Jaden Farquhar</h1>
                        <p>Newton Massachusetts born and raised. A long-time fan of science fiction, from Star Trek to 2001: Space Odyssey.</p><br/>
                        <p>
                            When the 2020 pandemic hit I finally got the chance to pitch in my own work. 
                            What should’ve been a smooth high school graduation year saw the world turned upside down. 
                            The uncertainties of isolation inspired for me a world light-years from Earth, all starting with 
                            a security worker living on an icy planet digging for clues a little in over his head. 
                            That work came to be my debut novel ESSO Rocky Frontier, and from the moment I wrote it, I was hooked.
                        </p><br/>
                        <p>Follow me on this writing journey, as I explore new worlds, populated by people with wild agendas and crazy adventures.</p><br/>
                        <p>To support me and keep up with my content, follow my instagram <a className="instagram-link" href="https://www.instagram.com/jadenfarquhar/"> @jadenfarquhar</a>. Or, to be notified for new releases, join the mailing list.</p>
                        <MailingFrom />
                        <br></br>
                        <br></br>
                        <br></br>
                        <p> Want to get in touch? Click on the button below to send me an email. I'll get back to you as soon as I can:</p>
                        <a id="hoverEffectTemp" href="mailto:jadenfarquhar@gmail.com?Subject=Hello%20again" target="_top">email me</a>
                    </div>
                </div>
                <div className="item2">
                    <img src={JadenFace} alt="Jaden Farquhar" />
                </div>
            </div>
        </div>
    );
}

export default About;