import React from "react";
import {Link} from "react-router-dom";

function Nav() {

    return (
        <div className="Nav">
            <Link className="link" to="/"><p>Jaden Farquhar</p></Link>
            <ul className="nav-links">
                <Link className="link" to="/"><li>Home</li></Link>
                <Link className="link" to="/books"><li>My Books</li></Link>
                <Link className="link" to="/about"><li>About Me</li></Link>
                <Link className="link" to="/lore"><li>Lore</li></Link>
            </ul>   
        </div>
    );
}

export default Nav;