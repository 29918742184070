import React from "react";

//import device from "react";

import TransportVessel from "./Schematics/TransportVessel.png"
import EnvironmentSuit from "./Schematics/EnvironmentSuit.png"
import LarryPistol from "./Schematics/LarryPistol.png"
import MagnetBoots from "./Schematics/MagnetBoots.png"
import Earpiece from "./Schematics/Earpiece.png"
import EricArm from "./Schematics/Eric'sArm.png"
import Buggy from "./Schematics/Buggy.png"
import Orca from "./Schematics/Orca.png"

import vonique from "./Schematics/vonique-64/Vonique 64 Bold.ttf"

var pmarginAmount = '15%';

//Make the pictures and fonts just two different classes so it's easier to adjust for all 
//Flexibile font size that decreases for mobile?
function Schematics(){
    return(

<div ClassName = "Schematics" style = {{backgroundColor: "black"}} >

<div style={styles.schematicContainer}>
        <p style = {styles.title}>ESSO Prototype Schematics v0.61</p>
    </div>

   
   <div style={styles.schematicContainer}>
    <div style = {{flex:2}}></div>
    <img src = {TransportVessel} alt = "TransportVessel" style = {styles.schematic}/>
    <div style = {{flex:2}}></div>
    <br></br>
   
    <br></br>
    </div>

    <br></br>

    <div style={styles.paragraphContainer}>
    <p style = {styles.paragraph}>The Transport Vessel, the interstellar ship responsible for transporting
    colonists and supplies across light years to and from ESSO Colonies, undertaking years-long journeys. Capable of transporting tons of supplies, a fleet of Orcas, and hundreds of colonists at once. With Artificial Gravity within the Ring Segments, and a nuclear fusion engine capable of peak acceleration around
    75% the speed of light.</p>
    </div>

    <br></br>

    <br></br>

   <div style={styles.schematicContainer}>
    <div style = {{flex:2}}></div>
    <img src = {EnvironmentSuit} alt = "EnvironmentSuit" style = {styles.schematic}/>
    <div style = {{flex:2}}></div>
    <br></br>
   
    <br></br>
    </div>

    <br></br>

    <br></br>   

    <div style={styles.paragraphContainer}>
    <p style = {styles.paragraph}>The Environment Suit, designed to protect the user against the hyper low pressure environment of a vacuum, as well as other extreme environments 
    including extreme cold, moderate-extreme heat, underwater pressure, and harsh winds. Composed of a patented custom fabric that is relatively lightweight but resistant to tears. Featuring a compressed oxygen system resistant to pressure changes. Built-in 
    earpiece compatibility and various utilities. Variants of the base design here include the ESSO-3 model, still in production at this time, with heavier reinforced armor afforded due to the relatively low gravity (70%), and required due to harsh environmental challenges.</p>
    </div>
    
    <br></br>

    <br></br>

    <div style={styles.schematicContainer}>
    <div style = {{flex:2}}></div>
    <img src = {LarryPistol} alt = "LarryPistol" style = {styles.schematic}/>
    <div style = {{flex:2}}></div>
    <br></br>
   
    <br></br>
    </div>

    <br></br>

    <br></br>   

    <div style={styles.paragraphContainer}>
    <p style = {styles.paragraph}>Standard issue pistol utilized by the ESSO Colonies' Security Sectors. Smart guns built pressure-sensitive to the grip of the user, assigned uniquely to each security guard. </p>
    </div>

    <br></br>

    <br></br>
    
    <div style={styles.schematicContainer}>
    <div style = {{flex:2}}></div>
    <img src = {MagnetBoots} alt = "MagnetBoots" style = {styles.schematic}/>
    <div style = {{flex:2}}></div>
    <br></br>
   
    <br></br>
    </div>

    <br></br>

    <br></br>  

    <div style={styles.paragraphContainer}>
    <p style = {styles.paragraph}>Standard issue footwear external to the environment suits with magnetic undersides used exclusively during space flight at colonists' disposal. Magnet boots are already used regularly aboard SOMTA space stations for ease
    of traversal in zero gravity environments. This variant designed for ESSO missions primarily prioritizes increased durability.</p>
    </div>

    <br></br>

    <br></br> 

    <div style={styles.schematicContainer}>
    <div style = {{flex:2}}></div>
    <img src = {Earpiece} alt = "Earpiece" style = {styles.schematic}/>
    <div style = {{flex:2}}></div>
    <br></br>
   
    <br></br>
    </div>

    <br></br>

    <br></br> 

    <div style={styles.paragraphContainer}>
    <p style = {styles.paragraph}>Primary communication device for use between team members during outdoor colonist operations, missions, and regular routine work. Fits both into the helmet of the environment suit or the wearer's ear. Can be modulated 
    from within an environment suit by pressing on external buttons. Capable of extended range. </p>
    </div>

    <br></br>

    <br></br> 
    
    <div style={styles.schematicContainer}>
    <div style = {{flex:2}}></div>
    <img src = {EricArm} alt = "EricArm" style = {styles.schematic}/>
    <div style = {{flex:2}}></div>
    <br></br>
   
    <br></br>
    </div>

    <br></br>

    <br></br> 

    <div style={styles.paragraphContainer}>
    <p style = {styles.paragraph}>Artifical limb example. SOMTA's robotic division has designed essential and intentionally uncomplicated standard models for articial limb construction, in the event of an 
    unfortunate traumatic amputation, a possibility in the foreign environments of ESSO Colonies. This arm is an example of one such artifical limb, featuring smooth rotary joints and precision of movement, and intricacy of control.</p>
    </div>

    <br></br>

    <br></br> 

    <div style={styles.schematicContainer}>
    <div style = {{flex:2}}></div>
    <img src = {Buggy} alt = "Buggy" style = {styles.schematic}/>
    <div style = {{flex:2}}></div>
    <br></br>
   
    <br></br>
    </div>

    <br></br>

    <br></br> 

    <div style={styles.paragraphContainer}>
    <p style = {styles.paragraph}>The standard electric motor all terrain vehicle utilized by colonists for efficient terrain traversal and transportation, referred to colloquially as "buggies." Built for durability and handling rough terrain. 
    An everyday alternative to Orcas, which heavily consume conventional fuel.</p>
    </div>

    <br></br>

    <br></br> 

    <div style={styles.schematicContainer}>
    <div style = {{flex:2}}></div>
    <img src = {Orca} alt = "Orca" style = {styles.schematic}/>
    <div style = {{flex:2}}></div>
    </div>

    <br></br>
   
    <br></br>

    <div style={styles.paragraphContainer}>
    <p style = {styles.paragraph}>The Orca model, the primary interplanetary and aerial ship for the colonies to utilize. Large enough to transport supplies and several personnel, but still maneuverable and efficient. With vertical landing and takeoff capability, using its powerful rotating engines.</p>
    </div>

    <br></br>

    <br></br> 

    




</div>
);

}


const styles = ({
    title: {
    fontSize: Math.round(window.innerWidth * 0.0351) /*(~which is about 50 for my computer screen)*/, 
    fontFamily: vonique, 
    fontWeight: "bold", 
    color : "silver"

    },

    paragraphContainer:{
    display: "flex", 
    justifyContent: "center", 
    alignItems:"center", 
    marginLeft: pmarginAmount, 
    marginRight: pmarginAmount
    },
    paragraph:{
    fontSize: Math.round(window.innerWidth * 0.0211) /*(~which is about 30 for my computer screen)*/, 
    fontFamily: vonique, 
    fontWeight: "bold", 
    color : "silver"

    },
    schematicContainer: {
    display: "flex", 
    flex: 1,
    justifyContent: "center", 
    alignItems:"center"

    },
   
    schematic: {
    flex:1, 
    width:'60%' 
    
    }
})
export default Schematics;