import React from "react";

import vonique from "../Schematics/vonique-64/Vonique 64 Bold.ttf";
var pmarginAmount = '5%';

function  GlobalGovernmentEntry(){
    return(
        <div ClassName = "GlobalGovernmentEntry" style = {{backgroundColor: "black"}} >
            <div style = {styles.titleContainer}>
            <div style = {styles.title}>
                <h1>Global Government</h1>

            </div>
            </div>

            <div style = {styles.paragraphContainer}>
                <p style = {styles.paragraph}>
                The Global Government is the unified Earth governing body that arose from the late 21st Century Global Crisis. The governing body initially began
                as a coalition movement between nations driven by new political and social developments. During the 22nd Century however, the Global Government gained universal adoption
                as it was fueled by issues of space-faring commerce and regulation. As companies took to space-faring enterprises and colonies were founded, governments adapted so as not to be left 
                behind in this economic revolution. Having individual nations regulating extraterrestrial business interactions was simply too impractical and arbitrary, 
                between spacecraft and in areas of the solar system where Earth nation borders had little meaning. By the time of Rocky Frontier, the Global Government
                has become the main governing body superseding national distinctions, as Humanity has reached all corners of the solar system; making national borders obsolete. 
                </p>
            </div>

           <br></br>
           <br></br>

            <div style = {styles.paragraphContainer}>
                <p style = {styles.paragraph}>The most notable exception is the situation on Luna, in which national borders are still relevant and upheld. The nationists of Luna
                include mainly the Americans, Chinese, and Russians, a notable Indian Colony as well; but they engage in coalition behavior on behalf of Luna's quasi-independence from the Global Government as a whole. 
                </p>

            </div>
            
            <br></br>
            <br></br>
            <br></br>
            <br></br>

        </div>
        

    )


}

//should put this in a separate css file so I can import it. 
//It's also used in Schematics.js
const styles = ({
    title: {
        fontSize: Math.round(window.innerWidth * 0.0351) /*(~which is about 50 for my computer screen)*/, 
        fontFamily: vonique, 
        fontWeight: "bold", 
        color : "silver"
    
        },
    titleContainer: {
        display: "flex", 
        flex: 1,
        justifyContent: "center", 
        alignItems:"center"
        
    },
    paragraphContainer:{
        display: "flex", 
        justifyContent: "center", 
        alignItems:"center", 
        marginLeft: pmarginAmount,
        marginRight: pmarginAmount
        },
    paragraph:{
        fontSize: Math.round(window.innerWidth * 0.0211) /*(~which is about 30 for my computer screen)*/, 
        fontFamily: vonique, 
        fontWeight: "bold", 
        color : "silver"
    
        },

})
export default GlobalGovernmentEntry;