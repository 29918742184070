import React, {useState, useEffect} from "react";
import Book from '../components/Book';

function Books() {

    useEffect(() => {
        fetchBooks();
    }, []);

    const [books, setBooks] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const fetchBooks = async () => {
        // const data = await fetch('https://esso-api.azurewebsites.net/api/bookprovider'); // Dev API
        const data = await fetch('/api/BookAPI');

        const booksJSON = await data.json();
        setBooks(booksJSON.books);
        setLoaded(true);

    };

    return (
        <div className="Books">
            {loaded? "":<p className="warning">Loading Books</p>}
            {books.map(book => (
                <Book key={book.id} series={book.series} name={book.name} author={book.author} description={book.description} image={book.image} amazon={book.amazon}/>
            ))}  
        </div>
    );
}

export default Books;