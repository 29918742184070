import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'; // https://github.com/leandrowd/react-responsive-carousel
import rocky_frontier_img from "../res/rocky_frontier.png";
import deadlock_img from "../res/deadlock.png";
import rocky_frontier_cover from "../res/rocky_frontier_cover.jpg";
import deadlock_cover from "../res/deadlock_cover.png";


function handleClick () {
    console.log('Button clicked!');
  };

function Home() {

    
    
    return (

        <div className="Home">
            <Carousel className="carousel-root-normal" autoPlay={true} infiniteLoop={true} showThumbs={false} showStatus={false} animationHandler='fade' swipeable={false} showArrows={false} renderIndicator={false}>
                <div>
                    <img src={rocky_frontier_img} alt="Rocky Frontier" />
                </div>
                <div>
                    <img src={deadlock_img} alt="Deadlock" />
                </div>
            </Carousel>
            <Carousel className="carousel-root-mobile" autoPlay={true} infiniteLoop={true} showThumbs={false} showStatus={false} animationHandler='fade' swipeable={false} showArrows={false} renderIndicator={false}>
                <div>
                    <img src={rocky_frontier_cover} alt="Rocky Frontier" />
                </div>
                <div>
                    <img src={deadlock_cover} alt="Deadlock" />
                </div>
            </Carousel>
            <div className="home-content">
                <h1 style = {{marginLeft: 0}}>Check Out Rocky Frontier link below ↓</h1>
                <view style = {{display:"flex", alignItems: "center"}}>
                <a className="amazon-link" href="https://www.amazon.com/Rocky-Frontier-ESSO-Book-1-ebook/dp/B09TCZF8FG/ref=sr_1_1" target="_blank" rel="noopener noreferrer">
                
                <button style={{ height:60 , width: 240, fontSize: 30, margin: 0}} onClick={handleClick}>Buy on Amazon</button></a>
                </view>
                <h1 text-align ="center" >Follow <a className="instagram-link" href="https://www.instagram.com/jadenfarquhar/"> @jadenfarquhar</a> for live updates, contact, and main content </h1>
            </div>
        </div>
    );
}

export default Home;